import { ENDPIONTS, httpService } from "@api";
import { AddSuspension } from "@components";
import { useFetch } from "@hooks";
import { Action, ComplexHeader, Table } from "@shared";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

interface Props {
  employeeId: number;
}
const EmployeeSuspensions = ({ employeeId }: Props) => {
  const MySwal = withReactContent(Swal);

  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<{ [key: string]: any }[]>(
    { endPoint: ENDPIONTS.employeeSuspensions, id: employeeId, reload: reload },
    []
  );

  useEffect(() => {}, [fetchData?.isFetching]);

  const headers: ComplexHeader[] = [
    { key: "fromPeriod", title: "from Period" },
    { key: "duration", title: "duration" },
    { key: "remarks", title: "remarks" },
    { key: "status", title: "Status" },
    { key: "applied", title: "applied" },
    { key: "createdBy", title: "createdBy" },
    { key: "createdAt", title: "@", format: "date" },
  ];

  const actions: Action[] = [
    {
      key: "",
      actionType: "badge",
      click: (a: any) => {
        onAddClick(null, a);
      },
      title: "Edit",
      color: "dark",
    },
    {
      key: "",
      actionType: "badge",
      click: (a: any) => {
        Swal.fire({
          title: "Are you sure you want to delete this record?",
          text: "This process is irrevocable, and the record will be permanently deleted.",
          icon: "warning",
          allowEscapeKey: false,
          allowOutsideClick: false,
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes, I Confirm!",
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const res = await httpService(ENDPIONTS.suspensions).delete(a?.id);
            if (res.status === 204) {
              setReload((prev) => (prev === undefined ? true : !prev));
              Swal.fire("Done!", "Record has been removed.", "success");
            } 
          },
        });
      },
      title: "Delete",
      color: "danger",
    },
  ];

  const onAddClick = (e: any, a?: any) => {
    e?.preventDefault();

    MySwal.fire({
      showConfirmButton: false,
      allowOutsideClick: false,
      showCloseButton: true,
      width: 800,
      html: (
        <AddSuspension
          row={a}
          employeeId={employeeId}
          callback={() => setReload((prev) => (prev === undefined ? true : !prev))}
        />
      ),
    });
  };

  return (
    <div className="row">
      <div className="container d-flex">
        <div className="card profile-box flex-fill">
          <div className="card-body">
            <h3 className="card-title">
              Payroll Suspensions
              <a
                href="!#"
                onClick={(e) => onAddClick(e)}
                className="edit-icon"
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
              >
                <i className="fa fa-plus" />
              </a>
            </h3>

            <Table
              actions={actions}
              class="table table-striped table-hover mb-0"
              data={fetchData?.data ?? []}
              headers={headers}
              isFetchingPage={fetchData.isFetching}
              showCounter
              // onPageChange={handlePageChange}
              paginationClass="row mt-3"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeSuspensions;
