import { ENDPIONTS, httpService, PaginatedResult, PagingOptions } from "@api";
import { useFetch } from "@hooks";
import { Allowance, AllowanceType, Deduction, DeductionType, OverTime, OverTimeType } from "@models";
import { useEmployeeStore } from "@stores";
import { AllowanceStatus, TypeOfDeduction } from "@viewModels";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

class NewDeduction {
  employeeId: number;
  deductionTypeId: number;
  amount: number;
  numberOfMonths: number;
}

interface Props {
  employeeId: number;
  deduction?: Deduction;
  callback?: () => void;
  isAdvance?: boolean; // this is used by the payrollpreview page
  maxAmountForAdvance?: number; // this is used by the payrollpreview page
}

const AddDeduction: React.FC<Props> = ({ callback, deduction, employeeId, isAdvance = false, maxAmountForAdvance }) => {
  const calculateIfSalaryIsDeductible = useEmployeeStore((s) => s.calculateIfSalaryIsDeductible);
  const calculateSalary = useEmployeeStore((s) => s.calculateSalary);

  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [selectedTypeOfDeduction, setselectedTypeOfDeduction] = useState<TypeOfDeduction>(
    TypeOfDeduction.OneTimePayment
  );

  const fetchDeductionType = useFetch<PaginatedResult<DeductionType>>(
    {
      endPoint: ENDPIONTS.deductionTypes,
      callbackParams() {
        var params = new PagingOptions();
        params.filter<DeductionType>((f) => f.eq("isRecurrentDeduction", false));

        return params;
      },
    },
    new PaginatedResult<DeductionType>()
  );

  useEffect(() => {}, [fetchDeductionType.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    setIsLoading(true);

    // means the request is coming from the payrollpreview page.
    //And the user can add advance while generating the payroll.
    //Therefore, don't check if the advance can be deduction from the employee's expected salary
    if (isAdvance) {
      if (!maxAmountForAdvance)
        return console.log("No value was passed to the maxAmountForAdvance. Check if the employee has a net amount.");
      if (parseFloat(data.amount) > maxAmountForAdvance) {
        Swal.fire({
          icon: "warning",
          showConfirmButton: false,
          text: "The advance amount can not be greater than the net amount of the employee",
        });
        setIsLoading(false);
        return;
      }
    } else {
      const monthlyDeduction = parseFloat(data.amount) / parseFloat(data.numberOfMonths ?? 1);

      const isDeductible = await calculateIfSalaryIsDeductible(employeeId, monthlyDeduction);

      if (!isDeductible) {
        Swal.fire({
          icon: "warning",
          showConfirmButton: false,
          text: "The employee's expected salary is bellow the accepted percentage.",
        });
        setIsLoading(false);
        return;
      }
    }

    let dType: NewDeduction = {
      employeeId: employeeId,
      deductionTypeId: !isAdvance ? data.deductionTypeId : 0,
      amount: data.amount,
      numberOfMonths: data.numberOfMonths,
    };

    if (deduction?.id) {
      // dType.id = allowance.id;
      // await httpService(ENDPIONTS.allowances).update(allowance.id, dType);
    } else {
      var res = await httpService(ENDPIONTS.deductions).post(dType);
      if (res.status === 201) {
        callback?.();
        Swal.close();
      }
    }

    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>{deduction?.id ? "Edit" : "Add"} deduction</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        {!isAdvance && (
          <div className="row">
            <div className="col">
              <div className="form-group">
                <label>deduction Type</label>
                <select
                  className="form-control select"
                  name="deductionTypeId"
                  ref={register({ required: true })}
                  onChange={(e) =>
                    setselectedTypeOfDeduction(
                      fetchDeductionType?.data?.items?.filter((t) => t.id === +e.target.value)[0].type
                    )
                  }
                >
                  <option></option>
                  {fetchDeductionType?.data?.items?.map((r: DeductionType, i: any) => {
                    return (
                      <option key={i} value={r.id}>
                        {r.name}
                      </option>
                    );
                  })}
                </select>
                <span className="text-danger">{errors.deductionTypeId && <span>This field is required</span>}</span>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col">
            <div className="col">
              <div className="form-group">
                <label>Amount</label>
                <div className="col-12">
                  <input
                    type="number"
                    step={0.01}
                    className="form-control"
                    name="amount"
                    ref={register({ required: true })}
                  />
                </div>
                <span className="text-danger">{errors.amount && <span>This field is required</span>}</span>
              </div>
            </div>
            <div className="col">
              <div className="form-group">
                <label>number Of Months</label>
                <div className="col-12">
                  <input
                    type="number"
                    className="form-control"
                    name="numberOfMonths"
                    defaultValue={selectedTypeOfDeduction === TypeOfDeduction.OneTimePayment ? 1 : 0}
                    disabled={selectedTypeOfDeduction === TypeOfDeduction.OneTimePayment}
                    ref={register({ required: true })}
                  />
                </div>
                <span className="text-danger">{errors.numberOfMonths && <span>This field is required</span>}</span>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row">
                    <div className='col'>
                        <div className="form-group">
                            <label 
                            onClick={}
                            >Installment</label>
                            <div className="col-12">
                                <input
                                    type="number"
                                    className="form-control"
                                    defaultValue={ }
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : fetchDeductionType.isFetching ? "Loading..." : "Submit"}
        />
      </form>
    </>
  );
};

export default AddDeduction;
