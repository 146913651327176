import { ENDPIONTS, httpService, PaginatedResult } from "@api";
import { useFetch } from "@hooks";
import { Branch, Department, Designation, DesignationResponse, SubBranch } from "@models";
import { BranchSelection } from "@widgets";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";

interface Props {
  selectedDesignation?: DesignationResponse;
  callback?: () => void;
}

const AddDesignation: React.FC<Props> = ({ selectedDesignation, callback }) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [subBranches, setsubBranches] = useState<SubBranch[]>([]);
  const [selectedBranchId, setSelectedBranchId] = useState(selectedDesignation?.branchId ?? 0);
  const [isLoadingSubBranches, setIsLoadingSubBranches] = useState(false);
  const [selectedSubBranchId, setSelectedSubBranchId] = useState(selectedDesignation?.subBranchId ?? 0);

  // const fetchDepartments = useFetch<PaginatedResult<Department>>(
  //   { endPoint: ENDPIONTS.departments, ignorePagination: true },
  //   new PaginatedResult<Department>()
  // );

  // const fetchBranches = useFetch<PaginatedResult<Branch>>(
  //   { endPoint: ENDPIONTS.departments, ignorePagination: true },
  //   new PaginatedResult<Branch>()
  // );

  useEffect(() => {
    if (selectedBranchId > 0) {
      (async function () {
        setIsLoadingSubBranches(true);

        const res = await httpService(ENDPIONTS.subBranches).getById(selectedBranchId);
        if (res.status === 200) setsubBranches(res.data);

        setIsLoadingSubBranches(false);
      })();
    }
  }, [selectedBranchId]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const designation: Partial<Designation> = {
      name: data.name,
      departmentId: data.departmentId,
      subBranchId: selectedSubBranchId,
    };

    if (selectedDesignation?.id) {
      designation.id = selectedDesignation?.id;
      const res = await httpService(ENDPIONTS.designations).update(selectedDesignation.id, designation);
    } else {
      const res = await httpService(ENDPIONTS.designations).post(designation);
      if (res.status === 201) {
        Swal.fire({
          icon: "success",
          text: "New Designation has been successfully registered.",
          showConfirmButton: false,
        });
      }
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Add New Designation</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        {/* <div className="row">
          <div className="form-group">
            <label>Department</label>
            <select className="form-control select" name="departmentId" ref={register({ required: true })}>
              <option></option>
              {fetchDepartments?.data?.items?.map((r: Department, i: any) => {
                return (
                  <option key={i} value={r.id} selected={r.id === selectedDesignation?.departmentId}>
                    {r.name}
                  </option>
                );
              })}
            </select>
          </div>
        </div> */}
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Branch</label>
              <BranchSelection
                register={register}
                isSelectedOption={selectedDesignation?.branchId}
                returnValue={(id: number) => setSelectedBranchId(id)}
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Sub-branch</label>
              <select
                className="form-control select"
                name="subBranchId"
                ref={register({ required: true })}
                disabled={selectedBranchId === 0}
                onChange={(e) => setSelectedSubBranchId(+e.target.value)}
              >
                {isLoadingSubBranches ? (
                  <option>Loading Data...</option>
                ) : (
                  <>
                    {" "}
                    <option></option>
                    {subBranches?.map((p, i: any) => {
                      return (
                        <option selected={selectedDesignation?.subBranchId === p.id} key={i} value={p.id}>
                          {p.name}
                        </option>
                      );
                    })}
                  </>
                )}
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>Designation</label>
              <div className="col-12">
                <input
                  defaultValue={selectedDesignation?.name}
                  type="text"
                  className="form-control"
                  placeholder="Enter designation"
                  name="name"
                  ref={register({ required: true })}
                />
              </div>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : selectedDesignation?.id ? "Update" : "Register"}
        />
      </form>
    </>
  );
};

export default AddDesignation;
