import { ENDPIONTS, httpService } from "@api";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  employeeId: number;
  callback: () => void;
  row: any;
}
const AddSuspension = ({ employeeId, callback, row }: Props) => {
  const { register, handleSubmit, errors, setValue } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (row?.id) {
      setValue("fromPeriod", row.fromPeriod);
      setValue("toPeriod", getEndMonth(row.fromPeriod, row.duration));
      setValue("remarks", row.remarks);
    }
  }, [row?.id]);

  const onSubmit = async (data: any, e: any) => {
    if (!employeeId) return;
    const duration = calculateDifference(data.fromPeriod, data.toPeriod);
    if (duration <= 0) {
      alert("Invalid duration");
      return;
    }

    setIsLoading(true);

    let emp = {
      employeeId: employeeId,
      fromPeriod: data.fromPeriod,
      duration: duration,
      remarks: data.remarks,
    };

    if (row?.id) {
      await httpService(ENDPIONTS.suspensions).update(row.id, emp);
    } else {
      await httpService(ENDPIONTS.suspensions).post(emp);
    }

    callback?.();
    setIsLoading(false);
    e.target.reset();
  };

  const calculateDifference = (startMonth: string, endMonth: string) => {
    if (!startMonth || !endMonth) return 0;

    const start = new Date(startMonth + "-01");
    const end = new Date(endMonth + "-01");

    if (end < start) return 0;

    const diff = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth()) + 1;

    return diff;
  };

  const getEndMonth = (startMonth: string, duration: number): string | null => {
    if (!startMonth || duration <= 0) return null;

    const start = new Date(startMonth + "-01");
    start.setMonth(start.getMonth() + duration - 1); // Adjust for inclusive count

    const year = start.getFullYear();
    const month = String(start.getMonth() + 1).padStart(2, "0");

    return `${year}-${month}`;
  };

  return (
    <>
      <h4>Suspend Employee</h4>
      <hr />

      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label>From Period</label>
              <div className="col-12">
                <input
                  type="month"
                  //   defaultValue={employee?.salary ?? ""}
                  className="form-control"
                  name="fromPeriod"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">{errors.fromPeriod && <span>This field is required</span>}</span>
            </div>
          </div>
          <div className="col">
            <div className="form-group">
              <label>To Period</label>
              <div className="col-12">
                <input
                  type="month"
                  //   defaultValue={employee?.salary ?? ""}
                  className="form-control"
                  name="toPeriod"
                  ref={register({ required: true })}
                />
              </div>
              <span className="text-danger">{errors.toPeriod && <span>This field is required</span>}</span>
            </div>
          </div>
          {/* <div className="col">
            <div className="form-group">
              <label>Duration In Payroll Periods</label>
              <div className="col-12">
                <input
                  type="number"
                  //   defaultValue={employee?.salary ?? ""}
                  className="form-control"
                  name="duration"
                  ref={register({ required: true })}
                  min={1}
                />
              </div>
              <span className="text-danger">{errors.duration && <span>This field is required</span>}</span>
            </div>
          </div> */}
        </div>
        <div className="row mt-3">
          <div className="col">
            <div className="form-group">
              <label htmlFor="remarks">Remarks</label>
              <textarea
                className="form-control mb-4"
                name="remarks"
                id="remarks"
                aria-label="With textarea"
                ref={register({ required: true })}
              ></textarea>
              <span className="text-danger">{errors.remarks && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>

        <input
          type="submit"
          name="time"
          className="btn btn-primary"
          disabled={isLoading}
          value={isLoading ? "Please wait..." : "Submit"}
        />
      </form>
    </>
  );
};

export default AddSuspension;
