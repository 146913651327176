import { ENDPIONTS, httpService } from "@api";
import { useFetch } from "@hooks";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

interface Props {
  leaveId: number;
}
const LeaveAttachment = ({ leaveId }: Props) => {
  const { register, handleSubmit, errors } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [reload, setReload] = useState<boolean | undefined>(undefined);

  const fetchData = useFetch<{ [key: string]: any }>(
    {
      endPoint: ENDPIONTS.leaveAttachment,
      id: leaveId,
      reload: reload,
    },
    {}
  );

  useEffect(() => {}, [fetchData.isFetching]);

  const onSubmit = async (data: any, e: any) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", data.file[0]);

    await httpService(ENDPIONTS.uploadLeaveAttachment).update(leaveId, formData);

    setReload((prev) => (prev === undefined ? true : !prev));
    setIsLoading(false);
    e.target.reset();
  };

  return (
    <>
      <h4>Add Attachment</h4>
      <hr />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="row">
          <div className="col">
            <div className="form-group">
              <label htmlFor="file">Select File</label>
              <input type="file" name="file" id="file" className="form-control" ref={register({ required: true })} />
              <span className="text-danger">{errors.file && <span>This field is required</span>}</span>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mt-3">
          {isLoading || fetchData?.data?.path ? (
            <input
              type="button"
              className="btn btn-sm btn-dark"
              value={"View Attachment"}
              onClick={() => window.open(fetchData?.data?.path)}
            />
          ) : (
            <span></span>
          )}
          <input
            type="submit"
            className="btn btn-sm btn-primary"
            disabled={isLoading}
            value={isLoading ? "Please wait..." : "Submit"}
          />
        </div>
      </form>
    </>
  );
};

export default LeaveAttachment;
